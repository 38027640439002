import { lazy, Suspense } from "react";
import { reactPageImports } from "../../template/metaData/reactPageImports";

const PageLoader = ({ page, segments }: { page: string; segments: any }) => {
  const Page = lazy((reactPageImports as any)[page]);
  return (
    <Suspense fallback={<h2>Falling back</h2>}>
      {<Page segments={segments} />}
    </Suspense>
  );
};
export default PageLoader;
