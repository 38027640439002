import { useContext } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import { DataContext } from "../../api/dataContext";
import Page from "../PageLoader";

const Main = () => {
  const { segments, pages } = (useContext(DataContext) as any) || {};

  const router =
    segments &&
    createBrowserRouter([
      {
        path: "/",
        Component: Outlet,
        children: [
          {
            index: true,
            element: <Page page="landing" segments={segments} />,
          },
          ...pages.map((page: any) => ({
            path: page,
            element: <Page page={page} segments={segments} />,
            // TODO make 404 page
            errorElement: <div>Unknown error occured</div>,
          })),
        ],
      },
    ]);
  // TODO: loading screen or whatev
  return segments ? <RouterProvider router={router} /> : null;
};
export default Main;
