import defaultData from "../../template/metaData/defaultData";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { DataContext } from "../../api/dataContext";
import Main from "../Main";

const queryClient = new QueryClient();
const App = () => {
  const [contextData, setContextData] = useState(defaultData);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { data, name } = event.data;
      if (name === "data") {
        setContextData(data);
      } else if (name === "navigate") {
        if (window.location.pathname !== data) {
          window.location.pathname = data;
        }
      } else if (name === "refresh") {
        window.location.reload();
      }
    });
    if (!contextData) {
      window.parent.postMessage("sendData", "*");
    }
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        window.parent.postMessage("exitFullscreen", "*");
      }
    });
  }, []);

  return (
    <div>
      <DataContext.Provider value={contextData}>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </DataContext.Provider>
    </div>
  );
};
export default App;
